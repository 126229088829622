import React, { useEffect, useState } from "react";
import logo from "../components/Cards_files/banner.png";
import paw from "../components/Cards_files/logo.png";

function Deposit() {
  const [username, setUsername] = useState("");
  useEffect(() => {
    setUsername(localStorage.getItem("username"));
  }, []);
  return (
    <body>
      <header>
        <div class="row align-items-end no-gutters bg-dark">
          <div class="col-10">
            <div class="row">
              <div class="col-8 offset-3 mt-3">
                <a href="/cards">
                  <img src={logo} alt="banner" />
                </a>
              </div>
            </div>
            <ul class="nav row mt-3 ml-2">
              <li class="nav-item dropdown-trigger">
                <a href="/cards" class="nav-link">
                  Cards
                </a>
              </li>
              <li class="nav-item dropdown-trigger">
                <a href="/deposit" class="nav-link">
                  Orders
                </a>
                <div class="dropdown bg-dark">
                  <ul class="nav flex-column">
                    <li class="nav-item">
                      <a href="/deposit" class="nav-link">
                        Cards
                      </a>
                    </li>
                  </ul>
                </div>
              </li>

              <li class="nav-item">
                <a href="/deposit" class="nav-link">
                  Tickets
                </a>
              </li>

              <li class="dropdown-trigger nav-item ml-auto">
                <a class="nav-link" href="/deposit">
                  Wallet ($00.00)
                </a>
                <div class="dropdown bg-dark" id="walletdropdown">
                  <p>0.00015898 BTC</p>
                  <div>
                    <a href="/deposit" class="btn btn-primary">
                      Deposit
                    </a>
                  </div>
                  --&gt;
                  <a href="/deposit">View history</a>
                  --&gt;
                </div>
              </li>
            </ul>
          </div>

          <div class="col p-2 dropdown-trigger" id="rightheader">
            <a href="/cards" id="logo">
              <img src={paw} alt="logo" />
            </a>
            <a href="/deposit" id="logo">
              <div>{username}</div>
            </a>
            <ul class="dropdown bg-dark nav flex-column" id="profiledropdown">
              <li class="nav-item">
                <a href="/deposit" class="nav-link">
                  Account settings
                </a>
              </li>
              <li class="nav-item">
                <a href="/deposit" class="nav-link">
                  Contact support
                </a>
              </li>
              <li class="nav-item">
                <a href="/deposit" class="nav-link">
                  Rules &amp; Terms of services
                </a>
              </li>
              <li class="nav-item">
                <a href="/deposit" class="nav-link">
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </header>

      <main class="container">
        <h1>Deposit</h1>
        <div class="alert alert-info">
          <ul>
            <strong>
              <span class="auto-style1">Pleas read this carefully</span>
            </strong>
            <li class="auto-style2">
              <em>
                <strong>Send any amount of bitcoins to this address</strong>
              </em>{" "}
            </li>
            <li class="auto-style2">
              <em>
                <strong>
                  Dont Send more than 1 transaction to this address or you could
                  lose your funds
                </strong>
              </em>{" "}
            </li>
            <li class="auto-style2">
              <em>
                <strong>
                  Wait until be 6 confirmations on blockchain network for your
                  transaction
                </strong>
              </em>{" "}
            </li>
            <li class="auto-style2 auto-style6">
              <em>
                <strong>
                  If your transaction got confirmed on blockchain and not posted
                  in your balance just refresh this page manually to update the
                  deposit status
                </strong>
              </em>{" "}
            </li>

            {/* <!--            <li><span class="auto-style2"><span class="auto-style4"><strong>
			<span class="auto-style5">Dont close this page until your 
			transaction get confirmed and the funds post in your balance or 
			reopen it when TX confirmed</span></strong></span></span> </li>
            <li><span class="auto-style2"><em><strong>Dont open a ticket for support before be sure your transactions has 6
                            confirmations at last</strong></em></span> </li>
    
        </ul>
    </div>--> */}
            <div class="auto-style3 deposit-address">
              {/* <!--<H2>Under update will back soon</H2>
</div>--> */}

              <div class="auto-style3 deposit-address">
                <a
                  rel="payment"
                  href="bitcoin:bc1qcy4zhhg40je5m7y4q98p8cknu0cc5xa2zvhkq4"
                >
                  bc1qcy4zhhg40je5m7y4q98p8cknu0cc5xa2zvhkq4
                </a>
              </div>
              <div class="alert alert-info">
                <br />A 2.0% deposit fee will be subtracted from the amount you
                send.
              </div>

              <ul class="mt-2 auto-style3">
                <li>Status: Waiting</li>
              </ul>
            </div>
          </ul>
        </div>
      </main>

      <footer>
        <div class="row justify-content-center">
          <div class="col-1">
            <a href="/deposit">FAQ</a>
          </div>
          <div class="col-1">
            <a href="/deposit">Guide</a>
          </div>
          <div class="col-1">
            <a href="/deposit">Rules</a>
          </div>
        </div>
        <div class="row justify-content-center mt-2">
          <div class="col">
            <p>1 BTC = $69554.98</p>
          </div>
        </div>
        <div>4th June 14:45 PM</div>
      </footer>
    </body>
  );
}

export default Deposit;
