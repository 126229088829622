import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Frontpage from "./Pages/Frontpage";
import Cards from "./Pages/Cards";
import Deposit from "./Pages/Deposit";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Frontpage />} />
        <Route path="/cards" element={<Cards />} />
        <Route path="/deposit" element={<Deposit />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
